<template>
  <div class="cadastro-usuario">
    <v-container fluid>

      <h1 class="titulo"> Prê-caixa </h1>
      
      <div class="box-table">

        <template>
          <filtroBase v-on:buscar="buscar" :historico="getHistorico" :loading="loadingTable" />
        </template>

        <v-skeleton-loader
          :loading="loadingTable"
          :transition="'fade-transition'"
          height="500"
          type="table">
          <v-data-table
          class="mt-4"
            :headers="headers"
            :search="search"
            :items="listaItens"
            width="300px"
            flat
            :expanded.sync="expanded"
            @click:row="expandRow"
            v-model="selected"
            hide-default-footer
            :loading="loadingPag"
            show-expand
            single-expand
            show-select
            :items-per-page.sync="itemsPerPage"
          >

            <template v-slot:item.credenciador="{ item }">
              {{ item.credenciador }}
            </template>
            <template v-slot:item.saldo="{ item }">
              {{ item.saldo | currency }}
            </template>

            <template v-slot:item.action="{ item }">
              <v-btn v-if="permiteAcao($route, 'baixar/add') && !getHistorico"  small @click="BaixarPreCaixa(item)" tile outlined class="mt-0 pb-0 pt-0 mr-2" :color="variables.colorSuccess" :loading="loadingBaixa"> Baixar atual </v-btn>
              <spam v-else small tile outlined class="mt-0 pb-0 pt-0 mr-2" :color="variables.colorSuccess">  </spam>
            </template>
        

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="table-expend ">
              <template v-for="(v, k) in listPreCaixaFormat">
                <v-row class="header" v-if="k === 0" :key="`${k}-header1`">
                  <!-- <v-col cols="1" sm="1" class="text-"> ID </v-col> -->
                  <v-col cols="1" sm="1" class="text-left"> Código </v-col>
                  <!-- <v-col cols="1" sm="1" class="text-left"> Nome  </v-col> -->
                  <!-- <v-col class="text-center"> Nome </v-col> -->
                  <v-col class=""> Data </v-col>
                  <v-col  class=""> Tipo </v-col>
                  <v-col cols="1" sm="1" class=""> Valor </v-col>
                  <v-col cols="1" sm="1" class=""> Saldo Atual </v-col>
                  <v-col cols="1" sm="1" class=""> Criado por </v-col>
                  <v-col cols="1" sm="1" class=""> Alterado por </v-col>
                  <v-col cols="1" sm="1" class=""> Dt.deletado </v-col>
                  <v-col class="text-center" > Ação </v-col>
                </v-row>
                <template>
                  <v-row :key="k" class="header-line" :class="{ isDeleted: isDeleted(v.deleted) }">
                    <!-- ID -->
                    <!-- <v-col cols="1" sm="1" class="text-left "> {{ v.id }} </v-col> -->

                    <!-- Name -->
                    

                    <v-col cols="1" sm="1"  class="text-left"> 
                      <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >{{ v.entity ? v.entity.cod : '-' }}</span>
                      </template>
                      <span>{{ v.id }}</span>
                    </v-tooltip>
                      
                    </v-col>
                    <!-- <v-col cols="1" sm="1"  class="text-left"> {{ v.entity ? v.entity.jsonData.name : '-' }} </v-col> -->

                    <!-- Data -->
                    <v-col class=""> {{v.created || v.create | datas('DD/MM/YYYY HH:mm:ss')}} </v-col>
                    
                    <!-- Tipo -->
                    <v-col class=" "> {{v.pendingOperationType.name}} </v-col>

                    <!-- Valor -->
                    <v-col cols="1" sm="1" class=" "> {{v.value | currency}} </v-col>
                    <v-col cols="1" sm="1" class=" "> {{v.anterior | currency}} </v-col>

                    <!-- dados de alteracoes do usuario -->
                    <v-col cols="1" sm="1" class=" "> {{ v.usuarioCreated ? v.usuarioCreated.name : createNameLancamento(v) }} </v-col>
                    <!-- <v-col cols="1" sm="1" class=" "> {{ v.createdUserEntities.jsonData.name }} </v-col> -->
                    <v-col cols="1" sm="1" class=" "> {{ v.usuarioUpdated ? v.usuarioUpdated.name : '-' }} </v-col>
                    <v-col cols="1" sm="1" class=" "> {{ getDateDeletad(v.deleted) }} </v-col>
                    <!-- Fim - dados de alteracoes do usuario -->

                    <v-col class="text-center" v-if="!getHistorico">

                      <v-btn v-if="permiteAcao($route, 'edit') && !isDeleted(v.deleted)"  small @click="openModal(v)" tile outlined class="mt-0 pb-0 pt-0 mr-2" :color="variables.colorPrimary"> Editar </v-btn>
                      <v-btn small v-if="permiteAcao($route, 'delete') && !isDeleted(v.deleted)" @click="deletarAction(v)" tile outlined class="mt-0 pb-0 pt-0" :color="variables.colorError">Excluir</v-btn>
                      
                      <v-chip  v-if="isDeleted(v.deleted)" dark :color="variables.colorError">Deletado</v-chip>
                    </v-col>
                    <v-col class="text-center" v-else>
                      {{ item.status}}
                      <v-chip v-if="!isDeleted(v.deleted)" :color="v.status === 'B' ? variables.colorSecondary: variables.colorPrimary" dark>{{ v.status === 'B' ? 'Baixado' : 'Não Baixado'}}</v-chip>
                      <v-chip v-else :color="variables.colorError" dark>Deletado</v-chip>
                    </v-col>

                  </v-row>
                </template>
              </template>
            </td>
          </template>
              <!-- <template v-slot:item.action="{ item }">
                <v-btn
                  v-if="permiteAcao($route, 'edit')"
                  small
                  @click="editItem(item)"
                  tile
                  outlined 
                  :color="variables.colorPrimary"
                  class="mr-2"
                >
                  Editar
                </v-btn>

                <v-btn
                  v-if="permiteAcao($route, 'delete')"
                  small
                  @click="deleteItem(item)"
                  tile
                  outlined 
                  :color="variables.colorError"
                >
                  Excluir
                </v-btn>
              </template> -->

              <!-- <template v-slot:footer>
                <div class="wrapper-footer">
                  <div class="qtd-paginacao">
                    {{ pageStart }}
                    -
                    {{ pageStop }}
                    de
                    {{ totalItens }}
                  </div>
                  <v-btn v-if="permiteAcao($route, 'baixar')" :disabled="selected.length === 0"  small @click="BaixarPreCaixa(item)" tile outlined class="br-btn baixar mt-0 pb-4 pt-4 mr-1" :color="variables.colorSuccess"> Baixar todos </v-btn>

                  <v-pagination
                    v-model="page"
                    :length="numberOfPages"
                    circle
                    :disabled="disabled"
                    :total-visible="7"
                    class="box-table-paginacao"
                    v-if="numberOfPages > 1"></v-pagination>
                </div>
              </template> -->
          </v-data-table>
        </v-skeleton-loader>
      </div>

      <v-btn class="mx-2 fab-cadastrar-usuario" fab color="primary" @click="cadastrar">
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
      <modalCadastro />
    </v-container>
  </div>
</template>

<script>
// eslint-disable-next-line
import Events from '@/core/service/events'
import listagemTables from '@/views/mixins/listagemTables'
// eslint-disable-next-line
import { map, flatten } from 'lodash'
// eslint-disable-next-line
import { errorSnackbar, successSnackbar, currency } from '@/core/service/utils'
import { mapActions, mapGetters } from 'vuex'
// eslint-disable-next-line
import moment from 'moment'
export default {
  name: 'PreCaixa',
  components: {
    // BrToolBar: () => import('./components/toolbar'),
    modalCadastro: () => import('./components/modal'),
    filtroBase: () => import('./components/filtroBase')
  },
  mixins: [listagemTables],
  data: () => ({
    historico: false,
    selected: [],
    dateFormat: 'YYYY-MM-DD',
    expanded: [],
    headers: [
      // { align: 'start', class: 'table-header', text: 'ID', value: 'id' },
      { align: 'start', class: 'table-header', text: 'Código', value: 'credenciador' },
      { align: 'start', class: 'table-header', text: 'Nome', value: 'credenciadorName' },
      { align: 'start', class: 'table-header', text: 'QTD', value: 'qtd' },
      { align: 'start', class: 'table-header', text: 'Total', value: 'saldo' },
      { align: 'start', class: 'table-header', text: '', value: 'action' },
    ],
    tipoBusca: 6,
    listaTipoBusca: [
      {text: 'ATS', value: 1 },
      {text: 'OSC ', value: 2 },
      {text: 'CREDENCIADOR ', value: 3 },
      {text: 'entity ', value: 4 },
      {text: 'CAIXA ', value: 5 },
      {text: 'USUARIO WEB ', value: 6 },
      {text: 'INFLUENCIADOR', value: 7 }
    ],
    typeBusca: 'entities',
    itensSelect: [],
    loadingBaixa: false
  }),
  computed: {
    ...mapGetters('preCaixa', ['listaItens', 'listDados', 'filtro', 'totalItens']),
    listPreCaixaFormat () {
      let dados = this.formatExpanded(this.expanded)

      dados = dados.sort((a, b) => {
        return parseInt(a.entity.cod) - parseInt(b.entity.cod)
      })

      return dados
    },
    getHistorico () {
      let history = this.historico
      return history
    }
  },
  mounted () {
    const vm = this
    this.getList(this.setParams()).finally(() => { this.loadingTable = false})
    this.getEntitiesTypes()
    Events.$on('Precaixa::reoladPage', (e) => vm.buscar(e))
  },
  watch: {
    itensSelect (val) {
      this.addDadosTable(this.envioSms(val))
    }
  },
  methods: {
    ...mapActions('preCaixa', ['editarItem', 'deletarItem', 'baixar', 'getEntitiesTypes']),
    ...mapActions('preCaixa', {getList: 'getDados'}),

     init() {},

     openModal (revenda) {
       const obj = {}
       obj.id = revenda.id
       obj.entityId = parseInt(revenda.entity.parentId)
       obj.promotor = {
         text: `(${revenda.promotor.cod}) ${revenda.promotor.jsonData.name}`,
         value: revenda.promotor.id
       }
       obj.params = {}
       obj.params.entity = {
         text: `(${revenda.entity.cod}) ${revenda.entity.jsonData.name}`,
         value: revenda.entity.id,
         parent: parseInt(revenda.promotor.id)
       }
       obj.value = revenda.value
       obj.pendingOperationTypeId = revenda.pendingOperationType.id
       obj.status = revenda.status
        Events.$emit('modalCadastro::precaixa', obj)
    },
    createNameLancamento (v) {
      return v.createdUserEntities ? v.createdUserEntities.name : ' Terminal '
    },
    expandRow(value) {
      this.expanded = value === this.expanded[0] ? [] : [value];
    },

    formatExpanded (obj) {
      if(obj[0]) {
        return obj[0].dados
      }
      return []
    },

    setParams() {
      return {
        page: this.page,
        pageSize: this.$store.getters.ItensPaginacao,
        module: 'Revendas',
        _addlFilter: {
          'pendingOperations:created_>=':moment().format(this.dateFormat + ' 00:00:00'),
          "pendingOperations:created_<=":moment().format(this.dateFormat + ' 23:59:59'),
          "pendingOperations:deleted_>=":moment('1970-01-01').format(this.dateFormat + ' 00:00:00'),
          'pendingOperations:status_=': 'N'
          // "pendingOperations:pendingOperationTypeId_In":'PC,PCB'
        }
      }
    },

    buscar (historico = false) {
      this.loadingTable = true
      const vm = this
      let params = this.setParams()
      const dados = this.filtro
      params._addlFilter['pendingOperations:created_>='] = `${moment(dados.dtStart, this.format).format('YYYY-MM-DD 00:00:00')}` 
      params._addlFilter['pendingOperations:created_<='] = `${moment(dados.dtEnd, this.format).format('YYYY-MM-DD 23:59:59')}`

      if (this.filtro.pStart !== undefined) {
        params._addlFilter['en:parentId_='] = this.filtro.pStart.value || this.filtro.vStart.parent
      } 
      if (this.filtro.vStart !== undefined) {
        params._addlFilter['pendingOperations:entityId_='] = this.filtro.vStart.value 
      }

      params._addlFilter['pendingOperations:status_='] = 'N'
      if (historico) {
        this.historico = true
        delete params._addlFilter['pendingOperations:status_=']
      } else {
        this.historico = false
      }

      this.expanded = []
      this.getList(params).then(() => {
        this.loadingTable = false
      }).catch(err => {
        errorSnackbar(err.error)
      }).finally(() => { 
        vm.loadingPag = false
        this.loadingTable = false
      })
    },

    deletarAction (item) {
      const vm = this
      this.$swal({
        icon: 'warning',
        text: `Tem certeza que deseja excluir o lançamento no valor de ${currency(item.value)}, da revenda: ${item.entity.cod}?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          vm.deletarItem({id: item.id}).then(() => {
            successSnackbar('Item excluído com sucesso!')
            vm.buscar()
          }).catch(err => {
            errorSnackbar(err.error)
          })
        }
      })
    },

    BaixarPreCaixa (item = null) {
      item.dados = item.dados.filter(it => moment(it.deleted).format('YYYY-MM-DD') === '1970-01-01')
      const vm = this
      vm.$swal({
        icon: 'warning',
        text: `Tem certeza que baixar ?`,
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
      }).then((result) => {
        if (result.value) {
          // se for selecionar mais de 1 ou só clicar no botao de baixar
          let dados = item === null ? this.selected : [item]
          let baixaPrecaixa = []

          dados.forEach(v => { // add todos o lançamentos em um unico array
            v.dados.forEach(a => baixaPrecaixa.push(a))
          })

          this.loadingBaixa = true
          vm.baixar({...baixaPrecaixa }).then(() => {
            successSnackbar('Pre-caixa baixado!')
            vm.buscar()
          }).catch(err => {
            errorSnackbar(err.error)
          })
          .finally(() => this.loadingBaixa = false)
        }
      })
    },

    isDeleted (dateDeleted) {
      return moment(dateDeleted).format('YYYY-MM-DD') !== '1970-01-01'
    },

    getDateDeletad (dateDeleted) {
      return this.isDeleted(dateDeleted) ? moment(dateDeleted).format('DD/MM/YYYY HH:mm') : '-'
    }
  }
}
</script>

<style lang="scss">
  @import '../../../assets/styles/components/table';
  @import '~@/assets/styles/components/modal-cadastro';
  .v-btn--contained{
    box-shadow: none !important;
  }
  .v-data-table thead tr th,
  .v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: $colorPrimary !important;
  }

  .box-table {
    .v-data-table-header-mobile {
      display: none;
    }
  }

.fab-cadastrar-usuario {
  position: fixed;
  z-index: 1;
  bottom: 25px;
  right: 5px;

  @media (min-width: 599px) {
    display: none;
  }
}

table tbody tr{
  cursor: pointer;
  // border: 1px solid #ccc;
}
.theme--light.v-data-table tbody tr.v-data-table__selected{
  background: #14800040;
  &:hover{
    background: #14800040 !important;
  }
}
table tbody tr.v-data-table__expanded__row {
  background: #f1f1f1;
}
table tbody tr.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none;
  td.table-expend  {
  }
  box-shadow: none;
  padding-top: 0;
  .row {
    & > div {
      border-bottom: 1px solid #ccc;
      // border-top: 1px solid #ccc;
      // display: flex;
      // justify-content: center;
      // align-items: center;
    }
    background: #d9d9D9;
    &:nth-child(2n +1) {
      background:   #e9e9e9;
    }
    transition: 0.5;
    // border-bottom: 1px solid #ccc;
    &:hover{
      background: #F1F1F1;
    } 
  }
  .row.header-line {
    &.isDeleted {
      color: #E53935
    }

    margin-top: 0;
    margin-left: -15px !important;
    margin-right: -15px !important ;
    &:last-child{
      margin-bottom: 0px;
    }
  }
  td > .header {
    margin-top: 0px;
    cursor: hand;
    background:white !important;
    div {
      font-weight: bold;
    }
    &:hover{
      background: white !important;
      
    }
  }
  td> .row.header-line {
    margin: 0;
  }
}
.wrapper-footer button {
  // // height: 36px;
  // // border-radius: 10px;
  // &:disabled{
  //   cursor: initial;
  //   border: none;
  //   background:#ccc !important;
  //   span{
  //     color: #666 !important;
  //   }
  // }
  // background: #1cb4ff !important;
  // height: 40px !important;
  // border-radius: 5px !important;
}

</style>
